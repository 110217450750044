import { createDefaultI18n } from '@alcs/i18n';

import { EnumBundle, EnumI18n } from './EnumTypes';

enum ShareableDocumentType {
    CON = 'CON',
    LSC = 'LSC',
    TRS = 'TRS',
}

const ShareableDocumentTypeI18n = createDefaultI18n<EnumI18n<typeof ShareableDocumentType>>(
    'enums.ShareableDocumentType',
    {
        CON: 'Contract',
        LSC: 'Load',
        TRS: 'Transportation',
    },
);

export const ShareableDocumentTypeBundle: EnumBundle<typeof ShareableDocumentType> = {
    value: ShareableDocumentType,
    defaultI18n: ShareableDocumentTypeI18n,
};
