import React from 'react';
import { Alert } from '@mui/material';

import { useReceiver } from '../SessionStorage/useTransporter';
import {
    completedRegistrationTransporter,
    invitationErrorTransporter,
    passwordResetErrorTransporter,
    registrationErrorTransporter,
    sessionExpiredErrorTransporter,
} from '../utils/Transporters';
import { useLoginI18n } from '../utils/useLoginI18n';

export const LoginPageReceivedAlerts = () => {
    const i18n = useLoginI18n();

    const [receivedCompletedReg, receivedCompletedRegValue] = useReceiver(completedRegistrationTransporter);
    const [receivedRegError, receivedRegErrorValue] = useReceiver(registrationErrorTransporter);
    const [resetPassErrReceived, resetPassError] = useReceiver(passwordResetErrorTransporter);
    const [receivedExpiredSessionError, receivedExpiredSessionErrorValue] = useReceiver(sessionExpiredErrorTransporter);
    const [invitationErrorReceived, invitationError] = useReceiver(invitationErrorTransporter);

    return (
        <React.Fragment>
            {receivedCompletedReg && receivedCompletedRegValue && (
                <Alert severity="success">{i18n.registrationCompleted}</Alert>
            )}
            {receivedRegError && receivedRegErrorValue && <Alert severity="error">{receivedRegErrorValue}</Alert>}

            {receivedExpiredSessionError && receivedExpiredSessionErrorValue && (
                <Alert severity="error">{receivedExpiredSessionErrorValue}</Alert>
            )}
            {resetPassErrReceived && resetPassError && <Alert severity="error">{resetPassError}</Alert>}
            {invitationErrorReceived && invitationError && <Alert severity="error">{invitationError}</Alert>}
        </React.Fragment>
    );
};
