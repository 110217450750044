export enum ClassifierDomains {
    COUNTRY = 'COUNTRY',
    RENTED_TRUCK_CHANGE_REASON = 'TRUCK CHANGE REASON',
    TRUCK_EURO_CLASS = 'TRUCK_EURO_CLASS',
    OEM_REQUEST_STATUS = 'OEM_REQUEST_STATUS',
    LANGUAGE = 'LANGUAGE',
    OFFER_STATUS = 'OFFER STATUS',
    ROUTING_SERVICES_PROVIDER = 'ROUTING_SERVICES_PROVIDER',
    INCIDENT_TYPE = 'INCIDENT TYPE',
    APPLICATION_STATUS = 'APPLICATION STATUS',
    MAKE_TRC = 'TRC',
    MAKE_TRL = 'TRL',
    TRUCK_TYPE = 'TRUCK TYPE',
    VIN_CHANGE_REASON = 'VIN_CHANGE_REASON',
    PLAN_SUBSCRIPTION_STATUS = 'PLAN SUBSCRIPTION STATUS',
    SERVICE_PLAN_CODE = 'SERVICE PLAN CODE',
    SERVICE_PLAN_PERIOD = 'SERVICE PLAN PERIOD',
    DRIVER_LANGUAGE = 'DRIVER_LANGUAGE',
    DEPOSIT_PMNT_METHOD = 'DEPOSIT PMNT METHOD',
    DEPOSIT_STATUS = 'DEPOSIT STATUS',
    POSITION_MAIL_PERIODICITY = 'POSITION MAIL PERIODICITY',
    CANCEL_REASON = 'CANCEL REASON',
    PAYMENT_TYPE = 'PAYMENT TYPE',
    PAYMENT_STATUS = 'PAYMENT STATUS',
    DOCUMENT_TYPE = 'DOCUMENT TYPE',
    ORDER_TYPE = 'ORDER TYPE',
    DAMAGE_PLACE = 'DAMAGE PLACE',
    DAMAGE_TYPE = 'DAMAGE TYPE',
    DAMAGE_DEGREE = 'DAMAGE DEGREE',
    NOTIFICATION_RECIPIENT_TYPE = 'NOTIFICATION RECIPIENT TYPE',
    FUEL_PRICE_ZONE = 'FUEL PRICE ZONE',
    FUEL_PRICE_LEVEL = 'FUEL PRICE LEVEL',
    RENT_END_REASON = 'RENT END REASON',
    ORDER_STATUS = 'ORDER STATUS',
    ORDER_FINANCIAL_STATUS = 'ORDER FINANCIAL STATUS',
    INVOICE_PRICE_TYPE = 'INVOICE PRICE TYPE',
    COMPANY_TYPE = 'COMPANY TYPE',
    COMPANY_STATUS = 'COMPANY STATUS',
    INSURANCE_TYPE = 'INSURANCE TYPE',
    LICENSE_TYPE = 'LICENSE TYPE',
    LICENSE_STATUS = 'LICENSE STATUS',
    ARRIVAL_NOTE_TYPES = 'ARRIVAL NOTE TYPES',
    ARRIVAL_NOTE_STATUS = 'ARRIVAL NOTE STATUS',
    SMS_STATUS = 'SMS STATUS',
    SMS_TYPE = 'SMS TYPE',
    CONTACT_USAGE_TYPE = 'CONTACT USAGE TYPE',
    CONTACT_TYPE = 'CONTACT TYPE',
    TOLL_TYPE = 'TOLL TYPE',
    TOLL_UNIT_TYPE_PAY_ROAD = 'TOLL UNIT TYPE PAY ROAD',
    TOLL_UNIT_TYPE_BRIDGE = 'TOLL UNIT TYPE BRIDGE',
    TOLL_UNIT_TYPE_FERRY = 'TOLL UNIT TYPE FERRY',
    TOLL_UNIT_TYPE_RAIL = 'TOLL UNIT TYPE RAIL',
    TOLL_UNIT_TYPE_TUNNEL = 'TOLL UNIT TYPE TUNNEL',
    TICKET_STATUS = 'TICKET STATUS',
    TRANSPORTATION_EXPENCE_TYPE = 'TRANSPORTATION EXPENCE TYPE',
    CURRENCY_CODE = 'CURRENCY CODE',
    INVITATION_STATUS = 'INVITATION STATUS',
    INVITATION_CANCEL_REASON = 'INVITATION CANCEL REASON',
    ADAC_CODE = 'ADAC CODE',
}
