import { ServiceUtils } from '@alcs/services';

/**
 * Function that loads specified i18n module.
 *
 * @param moduleName - name of module to load
 * @param language - specify language to load. If omitted, loads English translations.
 * @param version - version of i18n. Used only to bypass caching, by adding it as query parameter.
 */
export const loadI18n = async (
    moduleName: string,
    language?: string,
    version?: string,
): Promise<Record<string, unknown>> => {
    try {
        const axiosInstance = ServiceUtils.createI18nAxiosInstance(version ?? '');
        const i18n = await axiosInstance.get(
            moduleName,
            language
                ? {
                      params: {
                          lang: language.toLocaleLowerCase(),
                      },
                  }
                : undefined,
        );
        return i18n.data;
    } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
        return {};
    }
};
