/// <reference types="../popups" />
import React from 'react';
import { commonsDefaultI18n, useI18n } from '@alcs/i18n';
import { Typography } from '@mui/material';

import MuiPopup from './MuiPopup';
import { DisposableTransformer, transformData } from '../popups/ConstantDataTransformer';
import PopupController from '../popups/PopupController';

type ButtonTitles = { confirm?: string; cancel?: string };

type ConfirmPopupProps = {
    resolve: (value: boolean) => void;
    controller: PopupController;
    title: string;
    message: string;
    buttonTitles?: ButtonTitles;
    draggable?: boolean;
};

const ConfirmPopup = ({ resolve, controller, title, message, buttonTitles, draggable = true }: ConfirmPopupProps) => {
    const i18n = useI18n(commonsDefaultI18n);

    return (
        <MuiPopup
            draggable={draggable}
            title={title}
            onClose={() => resolve(false)}
            disableBackdropClick
            buttonBar={[
                {
                    title: buttonTitles?.confirm || i18n.ok,
                    onClick: () => {
                        resolve(true);
                        controller.dismiss();
                    },
                },
                {
                    title: buttonTitles?.cancel || i18n.cancel,
                    onClick: () => {
                        resolve(false);
                        controller.dismiss();
                    },
                },
            ]}
            controller={controller}
        >
            <Typography variant="h6">{message}</Typography>
        </MuiPopup>
    );
};

export const showConfirmPopup = (
    title: string,
    message: string,
    buttonTitles?: ButtonTitles,
    draggable?: boolean,
): Promise<boolean> => {
    return new Promise((resolve: (out: boolean) => void) => {
        window.popupsManager
            .add(
                transformData([new DisposableTransformer()], {
                    render: (controller: PopupController) => (
                        <ConfirmPopup
                            controller={controller}
                            title={title}
                            message={message}
                            resolve={resolve}
                            buttonTitles={buttonTitles}
                            draggable={draggable}
                        />
                    ),
                }),
            )
            .show();
    });
};
