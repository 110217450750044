import React from 'react';
import {
    alpha,
    Button,
    ButtonProps,
    createTheme,
    Icon,
    SimplePaletteColorOptions,
    Tab,
    Tabs,
    Tooltip,
    tooltipClasses,
    TooltipProps,
} from '@mui/material';
import { styled, ThemeOptions, useTheme } from '@mui/material/styles';
import { Appearance } from '@stripe/stripe-js';

import { hexToRGBA } from '../utils/ColorUtil';

const primary: SimplePaletteColorOptions = {
    main: '#67A8E4',
    dark: '#2d79b2',
    light: '#9bd9ff',
    contrastText: '#FFFFFF',
};

export const themeOptions: ThemeOptions = {
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 960,
            lg: 1280,
            xl: 1920,
        },
    },
    components: {
        MuiTooltip: {
            styleOverrides: {
                popper: {
                    zIndex: 100000,
                },
            },
            defaultProps: {
                disableInteractive: true,
                PopperProps: {
                    container: () => document.getElementById('reactPopovers'),
                },
            },
        },
        MuiStepper: {
            styleOverrides: {
                root: {
                    padding: 0,
                },
            },
        },
        MuiAlert: {
            defaultProps: {
                iconMapping: {
                    error: <Icon>error</Icon>,
                    info: <Icon>info</Icon>,
                    success: <Icon>check_circle</Icon>,
                    warning: <Icon>warning</Icon>,
                },
            },
            styleOverrides: {
                root: ({ theme, ownerState }) => ({
                    borderLeftWidth: 3,
                    borderLeftStyle: 'solid',
                    borderLeftColor: theme.palette[ownerState.severity ?? 'success'].main,
                    '.MuiAlert-icon': {
                        color: theme.palette[ownerState.severity ?? 'success'].main,
                    },
                }),
            },
        },
        MuiFormLabel: {
            styleOverrides: {
                root: {
                    '&:not(.MuiInputLabel-root)': {
                        color: 'rgba(0, 0, 0, 0.44)',
                        width: '100%',
                        textTransform: 'uppercase',
                        fontSize: 12,
                        fontWeight: 600,
                    },
                },
                asterisk: {
                    color: '#f44336',
                },
            },
        },
        MuiInputLabel: {
            styleOverrides: {
                root: {
                    lineHeight: 1,
                },
                outlined: {
                    transform: 'translate(14px, 7px) scale(1)',
                    '&.MuiInputLabel-shrink': {
                        transform: 'translate(14px, -6px) scale(0.75)',
                    },
                },
            },
        },
        MuiFormControlLabel: {
            styleOverrides: {
                root: {
                    marginLeft: 0,
                },
            },
        },
        MuiFormControl: {
            styleOverrides: {
                marginDense: {
                    marginTop: 5,
                    marginBottom: 2,
                },
            },
        },
        MuiRadio: {
            styleOverrides: {
                root: {
                    padding: 5,
                },
            },
        },
        MuiCheckbox: {
            styleOverrides: {
                root: {
                    padding: 5,
                },
            },
        },
        MuiButtonBase: {
            styleOverrides: {
                root: {
                    ':focus': {
                        outline: 0,
                    },
                },
            },
        },
        MuiIconButton: {
            styleOverrides: {
                root: {
                    padding: 7,
                },
                sizeSmall: {
                    padding: 3,
                    '& .material-icons': {
                        display: 'flex',
                    },
                },
            },
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    textTransform: 'inherit',
                    fontWeight: 400,
                    padding: '4px 10px',
                },
                text: {
                    textTransform: 'uppercase',
                },
                outlined: {
                    padding: '3px 10px',
                },
                containedPrimary: {
                    backgroundColor: '#67A8E4',
                    textTransform: 'inherit',
                },
            },
        },
        MuiButtonGroup: {
            styleOverrides: {
                root: ({ theme }) => ({
                    '& .MuiButton-outlined:last-child': {
                        borderTopRightRadius: theme.shape.borderRadius,
                        borderBottomRightRadius: theme.shape.borderRadius,
                        borderRightColor: 'var(--variant-outlinedBorder, currentColor)',
                    },
                    '& .MuiButton-outlined:first-child': {
                        borderTopLeftRadius: theme.shape.borderRadius,
                        borderBottomLeftRadius: theme.shape.borderRadius,
                    },
                    '& .MuiButton-containedPrimary:last-child': {
                        borderTopRightRadius: theme.shape.borderRadius,
                        borderBottomRightRadius: theme.shape.borderRadius,
                        borderRightWidth: 0,
                    },
                    '& .MuiButton-containedPrimary:first-child': {
                        borderTopLeftRadius: theme.shape.borderRadius,
                        borderBottomLeftRadius: theme.shape.borderRadius,
                    },
                    ':empty': {
                        display: 'none',
                    },
                }),
            },
        },
        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    '&.Mui-disabled': {
                        background: '#ededed',
                    },
                },
                multiline: {
                    padding: '6px',
                },
                input: {
                    padding: '6px',
                    height: '1.1876em',
                },
                inputAdornedEnd: {
                    paddingRight: 0,
                },
                adornedEnd: {
                    paddingRight: 6,
                },
                adornedStart: {
                    paddingLeft: 6,
                },
            },
        },
        MuiInputAdornment: {
            styleOverrides: {
                positionEnd: {
                    marginLeft: 4,
                },
                positionStart: {
                    marginRight: 4,
                },
            },
        },
        MuiInputBase: {
            styleOverrides: {
                root: {
                    '&.Mui-disabled': {
                        background: 'rgba(0,0,0,.05)',
                    },
                },
                input: {
                    '&::placeholder': {
                        opacity: 0.5,
                    },
                    '&.Mui-disabled': {
                        WebkitTextFillColor: 'rgba(0, 0, 0, 0.7)',
                    },
                },
            },
        },
        MuiTypography: {
            styleOverrides: {
                root: {
                    fontWeight: 400,
                },
            },
        },
        MuiTableSortLabel: {
            styleOverrides: {
                root: {
                    flexDirection: 'row-reverse',
                    justifyContent: 'space-between',
                    width: '100%',
                    '.label': {
                        width: '100%',
                    },
                    '&$active': {
                        color: primary.main,
                        '&& $icon': {
                            color: alpha(primary.dark!, 0.54),
                        },
                    },
                },
                icon: {
                    opacity: 0.4,
                    width: 15,
                    marginRight: 2,
                    marginLeft: 2,
                    '&$active': {
                        color: primary.main,
                    },
                },
            },
        },
        MuiSelect: {
            styleOverrides: {
                select: {
                    padding: '3px 0 4px 4px',
                },
            },
        },
        MuiLink: {
            styleOverrides: {
                root: {
                    textDecoration: 'none',
                    ':hover': {
                        textDecoration: 'underline',
                    },
                },
            },
        },
    },
    palette: {
        primary,
        error: {
            main: '#b00020',
            light: '#b00020',
            contrastText: '#FFFFFF',
        },
    },
    typography: {
        fontSize: 12,
        htmlFontSize:
            typeof window !== 'undefined' && window.location.pathname.includes('Public.html') ? 20 : undefined,
    },
};

export const theme = createTheme(themeOptions);

export const useStripeAppearance = (): Appearance => {
    const theme = useTheme();

    return {
        theme: 'none',
        variables: {
            fontFamily: theme.typography.fontFamily,
            borderRadius: '4px',
            colorPrimary: theme.palette.primary.main,
            colorDanger: theme.palette.error.main,
        },
        rules: {
            '.Input': {
                borderColor: 'rgba(0, 0, 0, 0.23)',
                borderWidth: '1px',
                borderStyle: 'solid',
                fontSize: '0.857143rem',
                padding: '6px',
            },
            '.Input:hover': {
                borderColor: '#000000',
            },
            '.Input:focus': {
                outline: `2px solid ${theme.palette.primary.main}`,
                outlineOffset: '-2px',
            },
            '.Label': {
                color: 'rgba(0, 0, 0, 0.44)',
                textTransform: 'uppercase',
                fontSize: '12px',
                fontWeight: '600',
            },
            '.Error': {
                fontSize: '12px',
                margin: '3px 6px',
            },
        },
    };
};

export const DefaultTooltip = (props: TooltipProps) =>
    props.title ? <LightTooltip enterDelay={500} {...props} /> : props.children;

export const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: theme.palette.common.white,
        color: 'rgba(0, 0, 0, 0.87)',
        boxShadow: theme.shadows[2],
        fontSize: theme.typography.body2.fontSize,
    },
}));

export const NoMaxWidthTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: theme.palette.common.white,
        color: 'rgba(0, 0, 0, 0.87)',
        boxShadow: theme.shadows[2],
        fontSize: theme.typography.body2.fontSize,
        maxWidth: 'none',
    },
}));

export const AlsMuiButton = styled(
    React.forwardRef(
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        ({ hasError, ...props }: ButtonProps & { hasError?: boolean }, ref: React.Ref<HTMLButtonElement>) => (
            <Button {...props} ref={ref} />
        ),
    ),
)(({ theme, hasError }) =>
    hasError
        ? {
              '&.MuiButton-outlined': {
                  color: theme.palette.error.main,
                  borderColor: theme.palette.error.main,
                  '&:hover': {
                      backgroundColor: alpha(theme.palette.error.main, 0.15),
                      borderColor: theme.palette.error.main,
                      color: theme.palette.error.main,
                  },
              },
              '&.MuiButton-contained': {
                  backgroundColor: theme.palette.error.main,
                  '&:hover': {
                      backgroundColor: theme.palette.error.dark,
                  },
              },
          }
        : undefined,
);

export const CustomTabs = styled(Tabs)(({ theme }) => ({
    borderBottom: '1px solid ' + hexToRGBA(theme.palette.text.primary, 0.15),
    '& .MuiTabs-indicator': {
        backgroundColor: theme.palette.primary.main,
    },
}));

export const CustomTab = styled(Tab)(({ theme }) => ({
    color: theme.palette.text.secondary,
    transition: 'background 0.3s ease, color 0.3s ease, border 0.3s ease',
    textTransform: 'inherit',
    '&:hover:not(.Mui-selected)': {
        color: theme.palette.text.primary,
        backgroundColor: hexToRGBA(theme.palette.text.primary, 0.1),
        borderBottom: '2px solid ' + theme.palette.text.primary,
    },
    '&.Mui-selected': {
        color: theme.palette.primary.main,
        backgroundColor: hexToRGBA(theme.palette.primary.main, 0.1),
        '&:hover': {
            color: theme.palette.primary.main,
            backgroundColor: hexToRGBA(theme.palette.primary.main, 0.1),
        },
    },
}));

export const DetailsTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: '#fff',
        color: 'rgba(0, 0, 0, 0.87)',
        maxWidth: 220,
        fontSize: theme.typography.pxToRem(14),
        borderRadius: 0,
        boxShadow: '1px 2px 5px rgba(0, 0, 0, 0.19)',
        padding: '6px 9px 6px 9px',
    },
}));

export const ErrorTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: 'rgb(253, 236, 234)',
        color: 'rgb(97, 26, 21)',
        maxWidth: 220,
        fontSize: theme.typography.pxToRem(14),
        borderRadius: 4,
        padding: '3px 5px',
        border: '1px solid #b00020',
    },
    [`& .${tooltipClasses.arrow}`]: {
        color: '#b00020',
        top: -1,
    },
}));
