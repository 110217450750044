import { useContext } from 'react';
import { createAsset } from '@alcs/query';

import { LanguageContext } from './LanguageContext';
import { loadI18n } from './loadI18n';
import { VersionContext } from './VersionContext';

const useI18nAsset = createAsset('loadI18n', loadI18n);

/**
 * Helper hook, that loads i18n module. Uses Suspense API.
 */
export const useI18nLoader = (moduleName: string) => {
    const language = useContext(LanguageContext);
    const version = useContext(VersionContext);
    return useI18nAsset(moduleName, language, version);
};
