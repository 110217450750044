import { IServiceCallback } from '@alcs/gwt';
import { useSafeContext } from '@sirse-dev/safe-context';
import { useSuspenseQuery } from '@tanstack/react-query';

import { FormNameContext } from './FormNameContext';
import { useQueryContext } from './QueryContext';

type RemoveFirstFromTuple<T extends unknown[]> = ((...args: T) => void) extends (
    firstArg: unknown,
    ...other: infer I
) => void
    ? I
    : [];

/**
 * @deprecated - use @tanstack/react-query `useQuery` or `useSuspenseQuery` instead
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const createQuery = <T extends (callback: IServiceCallback, ...args: any[]) => any>(key: string, fetcher: T) => {
    const useFetch = (...args: RemoveFirstFromTuple<Parameters<T>>): Awaited<ReturnType<T>> => {
        const { callback } = useQueryContext();
        const formName = useSafeContext(FormNameContext);

        const { data } = useSuspenseQuery({
            queryKey: [formName, key, ...args] as const,
            queryFn: ({ queryKey: [, , ...args] }) => fetcher(callback, ...args),
            gcTime: 0,
            staleTime: 0,
            refetchInterval: false,
            refetchIntervalInBackground: false,
            refetchOnMount: false,
            refetchOnReconnect: false,
            refetchOnWindowFocus: false,
            retry: false,
            retryOnMount: false,
        });

        return data;
    };

    return useFetch;
};
