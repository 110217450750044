import { createDefaultI18n } from '@alcs/i18n';

import { EnumBundle, EnumI18n, EnumIcons } from './EnumTypes';

export enum InvitationStatus {
    NEW = 'NEW',
    SUB = 'SUB',
    RECV = 'RECV',
    ACC = 'ACC',
    REJ = 'REJ',
    END = 'END',
    CAN = 'CAN',
}

const invitationStatusI18n = createDefaultI18n<EnumI18n<typeof InvitationStatus>>('enums.InvitationStatus', {
    NEW: 'New',
    SUB: 'Submitted',
    RECV: 'Received',
    ACC: 'Accepted',
    REJ: 'Rejected',
    END: 'Expired',
    CAN: 'Canceled',
});

const icons: EnumIcons<typeof InvitationStatus> = {
    ACC: 'StatusIcons/ACCEPT.svg',
};

export const InvitationStatusBundle: EnumBundle<typeof InvitationStatus> = {
    value: InvitationStatus,
    defaultI18n: invitationStatusI18n,
    icons,
};
