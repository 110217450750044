import { IServiceCallback } from '@alcs/gwt';
import { createSafeContext, useSafeContext } from '@sirse-dev/safe-context';

export type QueryContextType = {
    callback: IServiceCallback;
};

export const QueryContext = createSafeContext<QueryContextType>();

export const useQueryContext = () => useSafeContext(QueryContext);
