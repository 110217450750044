export enum DocType {
    CON = 'CON',
    UCON = 'UCON',
    INV = 'INV',
    OFR = 'OFR',
    ORD = 'ORD',
    ORD_CHILD = 'ORD.CHILD',
    ORDI = 'ORDI',
    TND = 'TND',
    TOFR = 'TOFR',
    TRQ = 'TRQ',
    RNT = 'RNT',
    NTL = 'NTL',
    CVH = 'CVH',
    CMP = 'CMP',
    CMPI = 'CMPI',
    UAPL = 'UAPL',
    APL = 'APL',
    DPST = 'DPST',
    APL_REG = 'APL_REG',
    APL_VAT = 'APL_VAT',
    VSH = 'VSH',
    AUL = 'AUL',
    APL_INS = 'APL_INS',
    PSUB = 'PSUB',
    PPAY = 'PPAY',
    CTR = 'CTR',
    CM = 'CM',
    VTRQ = 'VTRQ',
    BPMN = 'BPMN',
    INC = 'INC',
    RDAY = 'RDAY',
    RCTR = 'RCTR',
    CHAT = 'CHAT',
    OEM = 'OEM',
    BAC = 'BAC',
    COM = 'COM',
    CLAIM = 'CLAIM',
    USR = 'USR',
    ERQ = 'ERQ',
    LSC = 'LSC',
    CIN = 'CIN',
    DRV = 'DRV',
    LIC = 'LIC',
    DOC = 'DOC',
    APL_LIC = 'APL_LIC',
    TRS = 'TRS',
    CMR = 'CMR',
    CROU = 'CROU',
    QCD = 'QCD',
    INVT = 'INVT',
    TRQ_CON = 'TRQ.CON',
}
