import React, { PropsWithChildren, useCallback, useEffect, useRef, useState } from 'react';
import { Find_classifiers } from '@alcs/beans';
import { I18nContext, loadI18n } from '@alcs/i18n';
import { ReactiveFormProvider, useFieldValue, useForm, useFormContext } from '@reactive-forms/core';
import Cookies from 'js-cookie';
import isNil from 'lodash/isNil';

import { isServer } from '../utils/isServer';

type I18nResourcesFormValues = {
    lang: Find_classifiers;
};

export const useLanguagePath = () => {
    const { paths } = useFormContext<I18nResourcesFormValues>();

    return paths.lang;
};

export const useLanguage = () => {
    const languagePath = useLanguagePath();
    return useFieldValue(languagePath);
};

export type I18nResourceLoaderProps = PropsWithChildren<{}>;

export const I18nResourceLoader = ({ children }: I18nResourceLoaderProps) => {
    const [i18n, setI18n] = useState<Record<string, unknown>>({});

    const form = useForm<I18nResourcesFormValues>({
        initialValues: {
            lang: {
                classifier_code: 'LANGUAGE',
                value_code: 'EN',
                high_value: '0000000001',
                value_description: 'English',
            },
        },
    });

    const { paths, setFieldValue, values, getFieldValue } = form;

    const updateI18ns = useCallback(async (lang: string) => {
        try {
            const [commons, enums, components] = await Promise.all([
                loadI18n('commons', lang, process.env.BUILD_VERSION),
                loadI18n('enums', lang, process.env.BUILD_VERSION),
                loadI18n('components', lang, process.env.BUILD_VERSION),
            ]);

            setI18n({ commons, enums, components });
        } catch (error) {
            // eslint-disable-next-line no-console
            console.error(error);
        }
    }, []);

    const isFirstTime = useRef(true);

    useEffect(() => {
        if (!isServer() && isFirstTime.current) {
            isFirstTime.current = false;

            const language = Cookies.get('language');

            if (!isNil(language)) {
                setFieldValue(paths.lang, JSON.parse(language));
            }

            updateI18ns(getFieldValue(paths.lang.value_code));
        }
    }, [getFieldValue, paths.lang, setFieldValue, updateI18ns]);

    useEffect(
        () =>
            values.watch(paths.lang, lang => {
                Cookies.set('language', JSON.stringify(lang), { expires: 365 });
                updateI18ns(lang.value_code);
            }),
        [paths.lang, updateI18ns, values],
    );

    return (
        <I18nContext.Provider value={i18n}>
            <ReactiveFormProvider formBag={form}>{children}</ReactiveFormProvider>
        </I18nContext.Provider>
    );
};
