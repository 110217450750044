import { createDefaultI18n, ExtractI18nType } from './I18nContext';

export const commonsDefaultI18n = createDefaultI18n('commons', {
    required: 'Field is required',
    downloadXLS: 'Download as excel',
    chat: 'Open chat',
    chatBtnTitle: 'Chat',
    ok: 'Ok',
    cancel: 'Cancel',
    select: 'Select',
    delete: 'Delete',
    close: 'Close',
    edit: 'Edit',
    save: 'Save',
    submit: 'Submit',
    warning: 'Warning',
    enter: 'enter...',
    downloadXLSWarn:
        'More than :p0 records (:p1 in total) will be returned, it could take a time; do you really want to return all records?',
    setXLSRecordsCount:
        'There are more than :p0 records (:p1 in total) and it would take too long to export them all. Please specify how many records you want to export below.',
    maxRecords: 'Max records',
    lessThanMinValue: 'Value should not be less than :p0',
    moreThanMaxValue: 'Value should not be more than :p0',
    shouldIncludeLetters: 'Value should include letters',
    continue: 'Continue',
});

export type CommonsI18n = ExtractI18nType<typeof commonsDefaultI18n>;
