import { createDefaultI18n } from '@alcs/i18n';

import { EnumBundle, EnumI18n } from './EnumTypes';

export enum ReferenceName {
    MFOFRT = 'MFOFRT',
    INC = 'INC',
    MFORD = 'MFORD',
    MFOFR = 'MFOFR',
    CRORD = 'CRORD',
    CROFR = 'CROFR',
    TOS = 'TOS',
    TRS = 'TRS',
    BOF = 'BOF',
    DRV = 'DRV',
    TB = 'TB',
    APPL = 'APPL',
    COM = 'COM',
    SMS = 'SMS',
    CTR = 'CTR',
    CROFRT = 'CROFRT',
    MFOFRR = 'MFOFRR',
    CROFRR = 'CROFRR',
    ADC = 'ADC',
    INV = 'INV',
    ORD_RENT = 'ORD_RENT',
    UAPL = 'UAPL',
    DPST = 'DPST',
    PSUB = 'PSUB',
    PPAY = 'PPAY',
    LIC = 'LIC',
    CIN = 'CIN',
}

const defaultI18n = createDefaultI18n<EnumI18n<typeof ReferenceName>>('enums.RenferenceName', {
    INC: 'Issues (problems)',
    MFORD: 'Order',
    MFOFR: 'Transportation offer',
    CRORD: 'Transportation order',
    CROFR: 'Transportation offer',
    TOS: 'Transportation offer',
    TRS: 'Transportation details',
    BOF: 'Rental offer',
    DRV: 'Driver details',
    TB: 'Truck rentals',
    APPL: 'Company application',
    COM: 'Company details',
    SMS: 'SMS',
    CTR: 'Truck details',
    CROFRT: 'Transportation offer',
    MFOFRR: 'Truck rent offer',
    CROFRR: 'Truck rent offer',
    MFOFRT: 'Transportation offer',
    ADC: 'ADAC request',
    INV: 'Invoice',
    ORD_RENT: 'Fleet contract',
    UAPL: 'User application',
    DPST: 'Deposit',
    PSUB: 'Plan subscription',
    PPAY: 'Plan payments',
    LIC: 'Carrier licenses',
    CIN: 'Company details',
});

export const ReferenceNameBundle: EnumBundle<typeof ReferenceName> = {
    value: ReferenceName,
    defaultI18n,
};
