import { Spr_user_message_ot } from '@alcs/beans';
import { CurrencyType, CurrencyTypeSymbol } from '@alcs/enums';
import { FieldInnerError } from '@reactive-forms/core';
import isNil from 'lodash/isNil';

import { ComboBoxUtils } from '../components/fields/ComboBox/ComboBoxUtils';

export const insertParams = (text: string, params: string[]) => {
    let foundPosition;
    do {
        foundPosition = /:p[0-9]/.exec(text);
        if (foundPosition) {
            const paramIndexPosition = /[0-9]/.exec(foundPosition[0]);
            if (paramIndexPosition !== null) {
                const paramIndex = +paramIndexPosition[0];
                text =
                    text.substring(0, foundPosition.index) +
                    params[paramIndex] +
                    text.substring(foundPosition.index + foundPosition[0].length);
            }
        }
    } while (foundPosition);

    return text;
};

export const fileSizeToReadable = (fileSize: number) => {
    const sizes = ['b', 'kb', 'mb', 'gb'];
    if (fileSize == 0) {
        return `0${sizes[0]}`;
    }
    const index = Math.floor(Math.log(fileSize) / Math.log(1024));
    return parseFloat((fileSize / 1024 ** index).toFixed(2)) + sizes[index];
};

export const displayCurrency = (rawCurrency: string): string =>
    CurrencyTypeSymbol[rawCurrency as CurrencyType] ?? rawCurrency;

const numericLocale = 'fr-FR';

export const numberToReadable = (value: number, options?: Intl.NumberFormatOptions & { locale?: string }) => {
    return (
        value
            .toLocaleString(options?.locale ?? numericLocale, { minimumFractionDigits: 0, ...options })
            .replace(',', '.')
            // toLocaleString makes NARROW NO-BREAK SPACE, which is too narrow, so we replace them with NO-BREAK SPACE to make it wider.
            // see https://jkorpela.fi/chars/spaces.html
            .replace('\u202F', '\u00A0')
    );
};

export const percentageToReadable = (
    value: number,
    isFraction?: boolean,
    options?: Omit<Intl.NumberFormatOptions, 'style'>,
) => numberToReadable(isFraction ? value : value / 100, { maximumFractionDigits: 2, style: 'percent', ...options });

export const hoursToReadable = (value: number, options?: Intl.NumberFormatOptions & { locale?: string }) =>
    numberToReadable(value, {
        unit: 'hour',
        style: 'unit',
        unitDisplay: 'narrow',
        maximumFractionDigits: 0,
        ...options,
    });

export const priceToReadable = (value: number, currency: string, minimumFractionDigits = 2) =>
    numberToReadable(value, { minimumFractionDigits, maximumFractionDigits: 2, style: 'currency', currency });

export const kmToReadable = (distanceKm: number, options?: Intl.NumberFormatOptions) =>
    numberToReadable(distanceKm, { ...options, style: 'unit', unit: 'kilometer' });

export const hashString = (value: string) => {
    let hash = 0;
    if (value.length == 0) {
        return hash;
    }
    for (let i = 0; i < value.length; i++) {
        const char = value.charCodeAt(i);
        hash = (hash << 5) - hash + char;
        hash = hash & hash;
    }
    return hash;
};

export const hashObject = <B>(obj: B) => hashString(JSON.stringify(obj));

export const convertErrorsToReadable = (
    errors: Record<string, FieldInnerError>,
    labels: Record<string, string>,
): Record<string, string> =>
    Object.keys(errors).reduce<Record<string, string>>((acc, key) => {
        if (key !== '$error' && !!errors[key]?.$error) {
            acc[labels[key]] = errors[key].$error!;
        }
        return acc;
    }, {});

export const joinPaths = (...paths: (string | null | undefined)[]) => paths.filter(Boolean).join('.');

export const getTruckDisplayString = (truck_plate_no: string, trailer_plate_no?: string) =>
    [truck_plate_no, trailer_plate_no].filter(Boolean).join('/');

export const truncateText = (text: string, maxLength: number) => {
    if (text.length < maxLength) {
        return text;
    }

    return text.substring(0, maxLength) + '...';
};

export const formatError = ({ code, default_text }: Spr_user_message_ot) => default_text + (code ? `(${code})` : '');

export const parseBoolean = (value: string) => value === 'Y';

export const isUserFriendlyValue = (value: unknown): boolean => !isNil(value) && !Number.isNaN(value);

export const areUserFriendlyValues = (...values: unknown[]): boolean => values.every(isUserFriendlyValue);

export const hasLetters = (value: string) => /\p{L}+/u.test(value);

export const getCurrencySymbol = (currency: string) => priceToReadable(0, currency, 0).replace(/\d/g, '').trim();

export const getKmSymbol = () =>
    kmToReadable(0, { minimumFractionDigits: 0, maximumFractionDigits: 0 }).replace(/\d/g, '').trim();

const urlRe = /(https?:\/\/[^\s]+)/g;
const filterFalsy = Boolean as unknown as <T>(value: T | false) => value is T;
const clamp = (value: number, min: number, max: number) => Math.max(min, Math.min(value, max));
const getRealUrl = (value: string): string => {
    try {
        const url = new URL(value);
        return url.toString();
    } catch {
        return value;
    }
};

type Word = {
    type: 'text' | 'link';
    src: string;
    subparts: WordSubpart[];
};
type WordSubpart = { highlighted: boolean; text: string; pos?: 'begin' | 'end' | 'both' };

export const parseTextWithLinks = (text: string, searchTerm?: string): Word[] => {
    const words = text.split(urlRe).map((text, index) => {
        const type = index % 2 === 0 ? ('text' as const) : ('link' as const);

        const safeText = type === 'link' ? getRealUrl(text) : text;

        return {
            type,
            src: safeText,
            subparts: [{ highlighted: false, text: safeText }],
        };
    });

    if (!searchTerm) {
        return words;
    }

    const [before, substr] = ComboBoxUtils.splitBySubstring(text, searchTerm);

    const highlightBegin = before.length;
    const highlightEnd = highlightBegin + substr.length;
    let currentIndex = 0;

    let firstSubpart: WordSubpart | undefined;
    let lastSubpart: WordSubpart | undefined;

    for (let i = 0; i < words.length; ++i) {
        const word = words[i];
        if (highlightBegin <= currentIndex + word.src.length && highlightEnd >= currentIndex) {
            const substrBegin = clamp(highlightBegin - currentIndex, 0, word.src.length);
            const substrEnd = clamp(highlightEnd - currentIndex, 0, word.src.length);

            const highlightedPart = substrBegin !== substrEnd && {
                // Highlighted part
                highlighted: true,
                text: word.src.slice(substrBegin, substrEnd),
            };
            if (!firstSubpart && highlightedPart) {
                firstSubpart = highlightedPart;
            }
            if (highlightedPart) {
                lastSubpart = highlightedPart;
            }

            // Need to highlight part of word
            word.subparts = [
                substrBegin > 0 && {
                    highlighted: false,
                    text: word.src.slice(0, substrBegin),
                },
                highlightedPart,
                substrEnd < word.src.length && {
                    highlighted: false,
                    text: word.src.slice(substrEnd),
                },
            ].filter(filterFalsy);
        }
        currentIndex += word.src.length;
    }

    if (firstSubpart && lastSubpart) {
        if (firstSubpart === lastSubpart) {
            firstSubpart.pos = 'both';
        } else {
            firstSubpart.pos = 'begin';
            lastSubpart.pos = 'end';
        }
    }

    return words;
};

export const retrieveEmails = (value: string, badEmailList: string[]) => {
    const returnList: string[] = [];
    const pattern =
        /^[\s]*[_A-Za-z0-9-+]+(\.[_A-Za-z0-9-+]+)*@[A-Za-z0-9-]+(\.[A-Za-z0-9-]+)*(\.[A-Za-z]{2,}){1}[\s]*$/;

    if (value !== null && value !== undefined) {
        value = value.replace(/\s+/g, ',');
        const valueArr = value.split(/[,;]+/);

        valueArr.forEach(obj => {
            obj = obj.replace(/^.*<|>$/g, '');
            if (pattern.test(obj.trim())) {
                returnList.push(obj.trim());
            } else {
                badEmailList.push(obj.trim());
            }
        });
    }

    return returnList;
};

export const formatHoursToDaysHours = (value: number, locale = 'EN') => {
    const days = Math.floor(value / 24);
    const hours = value % 24;
    const daysFormat =
        days > 0 ? days.toLocaleString(locale, { style: 'unit', unit: 'day', unitDisplay: 'narrow' }) : '';
    const hoursForm =
        hours > 0 || days === 0
            ? hours.toLocaleString(locale, { style: 'unit', unit: 'hour', unitDisplay: 'narrow' })
            : '';
    return [daysFormat, hoursForm].filter(Boolean).join(' ');
};
