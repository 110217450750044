import React from 'react';
import { ListItemButton, ListItemButtonProps, ListItemText, TypographyProps } from '@mui/material';

export type DefaultItemProps = Omit<ListItemButtonProps, 'ref'> & {
    children: React.ReactNode;
    typographyProps?: TypographyProps<'div'>;
};

const DefaultItem = React.forwardRef(
    ({ children, typographyProps, ...other }: DefaultItemProps, ref: React.RefObject<HTMLDivElement>) => (
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        <ListItemButton dense {...(other as any)} ref={ref}>
            <ListItemText
                primaryTypographyProps={{
                    component: 'div',
                    variant: 'body1',
                    noWrap: true,
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    ...(typographyProps as any),
                }}
                title={typeof children === 'string' ? children : undefined}
            >
                {children}
            </ListItemText>
        </ListItemButton>
    ),
);

export default DefaultItem;
