import { useSuspenseQuery } from '@tanstack/react-query';

/**
 * @deprecated - use @tanstack/react-query `useQuery` or `useSuspenseQuery` instead
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const createAsset = <T extends (...args: any[]) => any>(key: string, fetcher: T) => {
    const useAsset = (...args: Parameters<T>): Awaited<ReturnType<T>> => {
        const { data } = useSuspenseQuery({
            queryKey: [key, ...args] as const,
            queryFn: ({ queryKey: [, ...args] }) => fetcher(...args),
            staleTime: Infinity,
            refetchInterval: false,
            refetchIntervalInBackground: false,
            refetchOnMount: false,
            refetchOnReconnect: false,
            refetchOnWindowFocus: false,
            retry: false,
            retryOnMount: false,
            gcTime: Infinity,
        });

        return data as Awaited<ReturnType<T>>;
    };

    return useAsset;
};
